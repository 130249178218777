// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

require("datatables.net")
require('datatables.net-bs4')
require("datatables.net-bs4/css/dataTables.bootstrap4.min.css")

const dataTables = [];

// Chosen
document.addEventListener("turbolinks:load", function() {
  $('.chosen-select').chosen({
    width: '25rem'
  })
})

// DataTables
document.addEventListener("turbolinks:load", () => {
  if (dataTables.length === 0 && $('.data-table').length !== 0) {
    $('table.data-table').each((_, element) => {
      dataTables.push($(element).DataTable({
        "autoWidth": false,
        "pageLength": 25,
        "stateSave": true
      }));
    });
  }
});

document.addEventListener("turbolinks:before-cache", () => {
  while (dataTables.length !== 0) {
    dataTables.pop().destroy();
  }
});

// Hidden/Fade Dealership Location
document.addEventListener("turbolinks:load", function() {
  $('.location-toggle-button').on("click", function(){
    $('.hidden-location-toggle').toggle(300);
    $('.fixed-location-toggle').toggle(100);
  })
});

// Parked Toggle
document.addEventListener("turbolinks:load", function() {
  $(".form-check-input").on("click", function (){
    $(".domain-parked-form").trigger("submit");
  });
});
